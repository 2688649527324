import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';

const NikkeGuidesMica: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Mica: Snow Buddy guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_xmica.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Mica: Snow Buddy guide & review</h1>
          <h2>
            A guide & review for Mica: Snow Buddy in NIKKE: Goddess of Victory.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_mica.jpg"
          alt="Mica"
        />
        <p>
          <strong>Mica: Snow Buddy</strong> banner will be available:{' '}
          <strong>
            From the end of the December 14, 2023 maintenance to 4:59:59,
            December 28, 2023 (UTC+9)
          </strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="mica-snow-buddy" enablePopover />
        </div>
        <p>
          Mica: Snow Buddy arrives in Neverland with full Christmas fashion. She
          looks so cute! Though her cuteness can definitely be matched with her
          "worry meter" around Belorta.. But don't fret! She turns her trauma
          into unbelievable strength in order to protect her friend! But now,
          down to business. Mica's skills focus on supporting the team in a
          multitude of ways: reducing the damage taken, buffing ammo, while also
          providing decent burst gen even though she is an SMG. She also opens
          up your teambuilding options with her skill of increasing the stack
          count of stackable buffs. Her burst is an atk buff, paired with a
          cleanse of 1 debuff. All in all, she's an interesting add to the
          roster, and will see some use in specific cases, but doesn't seem to
          break into the meta.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Mica: Snow Buddy (abbreviated as XMica throughout this guide) is a
          unit designed for cleansing debuffs and providing offensive buffs at
          the same time. Her cleanse is consistent, as it can be voluntarily
          activated at the start of every rotation. The ATK buff is decent but
          is inferior to other B1 buffers. Think of her as Liter who exchanges
          her firepower for situational debuff cleansing. That said, she may
          fall short of surpassing other B1 options with the lack of CDR and
          cover repair, which are inarguably valuable in many game modes.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>
            ■ Activates when landing 120 normal attacks. Affects all allies.
          </p>
          <p>
            Tidying Up: Damage Taken ▼ 2%, stacks up to 10 times and lasts for
            15 sec.
          </p>
          <p>
            ■ Activates when the caster's Tidying Up is fully stacked. Affects
            all allies.
          </p>
          <p>Max Ammunition Capacity ▲ 40% continuously.</p>
        </blockquote>
        <p>
          With every 120 shots landed, XMica accumulates a stack of Tidying Up,
          which reduces the damage taken by all allies for a long duration. When
          the maximum number of stacks is reached, she additionally provides
          everybody a decent amount of Max Ammo, comparable to a low-level OL
          line. This skill is not breathtaking in any way, but the Damage
          Reduction and the bonus Max Ammo may come in clutch, especially if
          your team has no healers and/or does not have enough Max Ammo. In
          fact, the buffs from this skill can be considered permanent, as they
          can be continually refreshed till the end of the battle.
        </p>
        <p>
          You can cheese her Max Ammo. What we mean is that you can "not"
          activate her Max Ammo buff on purpose by letting her lose stacks. This
          may be particularly useful when pairing her with units reliant on last
          bullet (like SAnis).
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates when landing 150 normal attacks. Affects all allies.
          </p>
          <p>Increases stack count of stackable buffs by 1.</p>
          <p>■ Activates when entering battle. Affects self.</p>
          <p>Burst Gauge filling speed ▲ 300% continuously.</p>
        </blockquote>
        <p>
          Stack refresh is what we call an "interesting trait" as only several
          units have access to such authority. It is also one of the fastest
          stack refresh in the game as it can mathematically be triggered every
          7.5~10s (150 shots + Resilience), but here's where the problem lies.
          It is dependent on skill level, which means she needs to shoot more at
          lower levels. Moreover, currently, there aren't many units that
          benefit from stack refresh, except for herself (you know...). The
          extra burst generation does help her a little in PvP and in teams with
          low burst regen, but whether that is useful depends on your
          composition.
        </p>
        <h6>Burst</h6>
        <blockquote>
          <p> ■ Affects all allies.</p>
          <p>Dispels 1 debuff.</p>
          <p>ATK ▲ 39.3% of caster's ATK for 5 seconds.</p>
        </blockquote>
        <p>
          Perhaps her redeeming factor—a not-so-bad ATK buff, which will always
          be meaningful regardless of its engraved value, followed by a reliable
          teamwide cleanse to liberate your team from undesired shackles.
          Chatterbox is one example of a boss that applies a stackable debuff
          which will instantly eliminate anyone with too many stacks. Removing
          that debuff leads to increased survivability, but sometimes you don't
          really need to remove it. You can use cover, dodge, or divert it. Even
          then, there are some enemies (such as Doctor) that apply inevitable
          stat penalty to your team, which unless cleased will technically last
          forever. Also be warned that her ATK buff is dependent on caster's
          ATK, not the targeted unit's ATK, making it slightly less effective on
          attackers.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          XMica will probably not see much usage outside Raids and PvP, and even
          then, there are way better units to choose from. She can be an
          alternative for beginners, however. There is a chance that XMica is a
          sleeper unit and will be used more when newer units with better
          synergy are released, such as units whose damage is reliant on
          stacking, particularly an SG wielder with CDR to further harmonize
          with Tove. Or someone like A2 who constantly buffs (and also debuffs)
          herself but without the coupling.
        </p>
        <h6>Bossing</h6>
        <p>
          Her primary content. When debuff cleansing is necessary, especially
          for removing ATK debuffs, she will most likely see some gameplay.
          Outside that, she may be used as an alternative B1 buffer. She may
          still see some usage in Team 4 or 5, if you don't own every meta unit.
        </p>
        <h6>PVP</h6>
        <p>
          At maximum level of Skill 2, she can generate Burst Energy fairly
          quickly, but it also comes with the disadvantage of feeding Jackal.
          Her ATK buff will come useful situationally, and Damage Reduction may
          improve survivability despite taking years to stack. She can be
          utilized as a cope unit until you acquire more suitable PvP
          mercenaries.
        </p>
        <h6>Sim</h6>
        <p>
          She is most likely not used for campaign, but we are distinguishing
          this section from the rest in preparation for the upcoming Simulation
          Overclock, which they announced not long ago. Unlike campaign, this
          mode probably does not incur stat penalty. XMica may prove useful when
          too much debuff is being applied by those pesky debuffer mobs from
          time to time. Note that we do NOT know what Simulation Overclock
          actually looks like, so this is just an early prediction.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Since XMica's ATK buff is dependent on caster's stats, the idea of
          upgrading her Helmet and Body is justified. However, the buff is weak
          and short, so there is only little gain. Pay attention to your
          resource consumption and manage it properly. It is probably wiser to
          leave it at base level and upgrade other units' gear. That said,
          giving her Max Ammo lines will shorten the window between triggers of
          S1 and S2, which might be useful depending on your team.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>Skill 1: 3/4</li>
          <ul>
            <li>
              Skill 1 provides team with increased Max Ammo, which is always
              nice to have, and Damage Reduction may help team survive against
              attacks that would have otherwise killed them (such as
              Chatterbox's slam). We recommend choosing either Level 3 or 4. At
              Level 3, she grants 2 ammo to all SR units if rounded up, whereas
              at Level 4, she grants 3 ammo to all SG units. All of these assume
              that there are no other Max Ammo buffs in team. If there are, the
              breakpoints might be altered.
            </li>
          </ul>
        </ul>
        <ul>
          <li>Skill 2: 3-7</li>
          <ul>
            <li>
              Each level increases gauge filling speed (more crucial in Arena)
              and reduces the number of shots needed to refresh team's stacks.
              If stack refresh or higher burst generation is urgently needed,
              then you may consider upgrading this skill. Otherwise, you should
              ideally stay away or just leave it at 3.
            </li>
          </ul>
          <li>Burst: 3-7 </li>
          <ul>
            <li>
              Ideally, any skill that increases ATK should be leveled to 10,
              including this one. Nevertheless, its worth is incomparable to
              other ATK-buffing skills. What's valuable about her Burst is the
              debuff cleanse, which fortunately does not scale past Level 1.
              This means, unless you need that tiny ATK juice, you can leave it
              at a low level like 3, 4, 5, or 7.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          Both Bastion and Resilience would serve her well for PvE purposes for
          more reliable stacking. In PvP, you can opt for Quantum instead (or
          even Vigor if she constantly dies before she can benefit from her
          Burst Skill).
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <p>
          Straight ahead, onward to Team Composition! Well, honestly, her teams
          are more or less the same as when you are pairing Pepper, N102,
          Miranda, or SMary with Dolla. There are some pretty interesting
          combinations you can try out, but you should only do it for fun
          instead of for maximizing damage. Here are some early compositions we
          can think of:
        </p>
        <h5>Team #1 - All-Around Composition</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="mica-snow-buddy"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="aqua-marine-helm"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          A basic setup involving XMica and a healer for survivability. Very
          beginner-friendly and would work in many game modes despite not being
          the most powerful setup. While this team is mainly used for dealing
          with debuffers, you can also use her just as an ATK buffer. Simply
          rotate normally but time her Burst well for maximum efficacy. Feel
          free to replace Helm with other healers if desired. If healer is not
          needed, just bring another DPS. For beginners, XMica is a decent
          buffer for Modernia because she can dispel the Ammo Reduction debuff
          she inflicts herself with.
        </p>
        <h5>Team #2 - Hypothetical "Forced" Tove Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tove" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="mica-snow-buddy"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dolla" enablePopover />
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sugar" enablePopover />
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A gimmicky composition that forcefully combines Tove and XMica. You
          may ask, why? Well, Tove is a unit whose offensive capabilities build
          up with stacks, and XMica accelerates stack accumulation and helps
          with stack maintenance. So, they have this hidden synergy. For your
          information, Tove's ATK buff is permanent as long as you refresh it
          with stack refresh before the duration ends! This theoretically means
          that your SG units may enjoy 70%+ ATK buffs for the entire battle.
          Unfortunately, this team is too expensive to actually test and may not
          be outputting damage worth the required investment. Do not use
          Marciana or Helm if healing is not needed; if needed, just pick one of
          them.
        </p>
        <h5>Team #3 - Machine Gun Heaven: Unlimited Ammo Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="mica-snow-buddy"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="diesel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guilty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          A reload-heavy team focused on infinite ammo without high levels of
          Burst Skill. By utilizing Privaty's Burst and her own Burst, you can
          reach infinite ammo easily with either Resilience or Bastion. XLud
          offers similar DPS here versus in the Liter counterpart (does not
          account for Part Damage). B2 can be Centi, Diesel, Sin, Quency, Novel,
          Marciana, etc. This variant can use either Bastion or Resilience.
          Manual play is encouraged.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <p>
          While she is not amazing for PvP, XMica's ATK buff is very much
          similar to Liter's, excellent for one-wiping the enemy team off the
          board. However, the low value (and dependability on caster's stats)
          may demand higher investments. Still, she generates Burst Energy
          faster than Liter, which may or may not be more important than the
          buff strength itself. Here is one team where you can slot her in:
        </p>
        <h5>Team #1 - All-Around Composition</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="mica-snow-buddy"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Scarlet has been known for easily wiping off teams and countering
          SAnis. Here, we suggest a 2.5-3RL setup. With the increased burst
          generation, XMica can easily compete with other buffer spots. Do note
          that, at higher sync levels, XMica may not offer enough ATK for
          Scarlet to wipe everyone fully, hence why she is paired with AnneMF
          here, another nikke available in rate-up banners right now! But, what
          we're trying to take advantage of here is Mica's debuff cleanse
          ability, which will allow her to counter relatively faster Blanc-based
          teams, as removing Blanc's Damage taken buff allows supporter and
          defender types to survive a non-buffed Scarlet's burst (unless its a
          crit) at higher sync levels.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          For the vast majority of players, we honestly cannot recommend
          obtaining her for now due to lack of versatility and exorbitant
          investment costs. However, if new units with better synergy are
          released in the future or if new bosses with impactful debuffs appear,
          this advice may change. Oh, speak of the devil—it's Chatterbox's Solo
          Raid! Thus, it is your decision if you want to take the risk of
          getting her for future-proofing purposes. We may see her being used in
          Simulation Overclock if it arrives with gimmicks, but that is just too
          hopeful. Unless you are using her in Solo Raid, or if you are trying
          to save gems, we suggest waiting for the last banner day to finalize
          your decision. We would also have received patch notes by then!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Cleanse debuffs and stack refresh; both are situationally
                    useful.
                  </li>
                  <li>
                    Some skills' trigger intervals can be shortened with Max
                    Ammo buffs and OL.
                  </li>
                  <li>Provides ATK buff, regardless of its low value.</li>
                  <li>
                    Provides permanent teamwide Damage Reduction and Max Ammo,
                    great for ammo-dependent units.
                  </li>
                  <li>
                    One of the better replacements for B1 in Raids and PvP if
                    you lack the Meta units.
                  </li>
                  <li>Decent Burst Generation thanks to her Skill 2.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Needs high investments, especially for Skill 2 to be fully
                    effective.
                  </li>
                  <li>
                    Gives caster's ATK buff, which means weaker buff for
                    Attackers.
                  </li>
                  <li>This also forces you to invest in her gear.</li>
                  <li>
                    Cannot compete with Top Tier B1 Nikkes due to her overall
                    weaker ATK buff.
                  </li>
                  <li>
                    Feeds Burst Energy to Jackal, restricting usability in PvP.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (Early)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Late)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMica;

export const Head: React.FC = () => (
  <Seo
    title="Mica: Snow Buddy guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Mica: Snow Buddy in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
